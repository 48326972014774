<template>
  <div class="page_company_list">
    <div class="content">
      <div style="margin: 0 auto 50px; width: 1020px">
        <div
          class="search_bar"
          :class="{ search_focus: searchFocus }"
          @click.stop="focusSearch"
        >
          <!--        <el-select v-model="place" >-->
          <!--          <el-option-->
          <!--              v-for="item in options"-->
          <!--              :key="item.value"-->
          <!--              :label="item.label"-->
          <!--              :value="item.value"-->
          <!--          />-->
          <!--        </el-select>-->
          <el-cascader
            placeholder="选择地区"
            @change="areaChange"
            style="width: 100px; margin-left: 22px"
            :show-all-levels="false"
            :props="areaProp"
            v-model="cityCode"
          ></el-cascader>
          <el-input
            v-model="keyword"
            placeholder="搜索公司"
            @focus="searchFocus = true"
            style="
              flex: 1;
              text-align: left;
              border: none;
              background-color: transparent;
            "
          />
          <el-button class="search_btn" @click="search">搜索</el-button>
        </div>
        <div class="hot_search">
          热搜：<span
            v-for="item in hotPositionKeywords"
            :key="item.id"
            @click="hotKeywordClick(item)"
            >{{ item.name }}</span
          >
        </div>
      </div>

      <div class="filter_area">
        <el-form :inline="true" style="text-align: left">
          <!--        <el-form-item label="地区">-->
          <!--          <el-cascader-->
          <!--              style="width:100%;"-->
          <!--              :show-all-levels="false"-->
          <!--              :props="areaProp" v-model="cityCode"></el-cascader>-->
          <!--        </el-form-item>-->
          <el-form-item label="行业">
            <el-cascader
              v-if="userStore.loadIndustryListComplete"
              style="width: 100%"
              @change="industryChange"
              :show-all-levels="false"
              :props="industryProp"
              v-model="industry"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="规模">
            <el-select v-model="param.companyScaleId">
              <el-option
                v-for="item in scaleList"
                :value="item.id"
                :key="item.id"
                :label="item.description"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="company_list content">
        <company-card
          v-for="item in jobList"
          :key="item.companyId"
          :item="item"
        ></company-card>
        <!--      <company-card></company-card>-->
        <!--      <company-card></company-card>-->
        <!--      <company-card></company-card>-->
        <!--      <company-card></company-card>-->
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="param.pageSize"
        v-model:current-page="param.pageNum"
        @current-page="pageChange"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import companyCard from "@/components/companyCard";
import {
  searchCompanyList,
  getCompanyScaleList,
  indexHotPositionKeywords,
} from "@/api/user/user";
import { getAreaList, getCityList, getProvinceList } from "@/api/user/area";
import { mapStores } from "pinia";
import { useUserStore } from "@/store/user/user";
export default {
  name: "companyList",
  data() {
    let that = this;
    return {
      searchFocus: false,
      keyword: "",
      // place:"北京",
      options: [
        {
          label: "北京",
          value: "北京",
        },
      ],
      scaleList: [],
      hotPositionKeywords: [],
      jobList: [],
      industry: [],
      industryProp: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let nodes = [];
          if (level === 0) {
            that.userStore.industryList.map((v) => {
              if (v.parentId === 0 || v.parentId === "0") {
                nodes.push({
                  label: v.name,
                  value: v.id,
                  leaf: false,
                });
              }
            });
            resolve(nodes);
          } else {
            that.userStore.industryList.map((v) => {
              if (v.parentId === node.data.value) {
                nodes.push({
                  label: v.name,
                  value: v.id,
                  leaf: true,
                });
              }
            });
            resolve(nodes);
          }
        },
      },
      total: 0,
      cityCode: [],
      param: {
        areaCode: "",
        cityCode: "",
        companyScaleId: "",
        industryClassificationId: "",
        pageNum: 1,
        pageSize: 10,
        query: "",
      },
      areaProp: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let nodes = [];
          if (level === 0) {
            getProvinceList().then((res) => {
              res.data.forEach((v) => {
                nodes.push({
                  label: v.provinceName,
                  value: v.provinceCode,
                  leaf: false,
                });
              });
              resolve(nodes);
            });
          } else if (level === 1) {
            getCityList({
              provinceCode: node.data.value,
            }).then((res) => {
              res.data.forEach((v) => {
                nodes.push({
                  label: v.cityName,
                  value: v.cityCode,
                  leaf: false,
                });
              });
              resolve(nodes);
            });
          } else if (level === 2) {
            getAreaList({
              cityCode: node.data.value,
            }).then((res) => {
              res.data.forEach((v) => {
                nodes.push({
                  label: v.areaName,
                  value: v.areaCode,
                  leaf: true,
                });
              });
              resolve(nodes);
            });
          }
        },
      },
    };
  },
  watch: {
    param: {
      handler() {
        this.searchCompanyList();
      },
      deep: true,
    },
  },

  components: {
    companyCard,
  },
  computed: {
    ...mapStores(useUserStore),
  },
  mounted() {
    indexHotPositionKeywords().then((res) => {
      if (res.code === 0) {
        this.hotPositionKeywords = res.data;
      }
    });
    getCompanyScaleList().then((res) => {
      if (res.code === 0) {
        this.scaleList = res.data;
      }
    });
    if (this.$route.query.keyword !== undefined) {
      this.keyword = this.param.query = this.$route.query.keyword;
    } else {
      this.searchCompanyList();
    }
  },
  methods: {
    areaChange() {
      this.param.cityCode = this.cityCode.length > 2 ? this.cityCode[1] : "";
      this.param.areaCode = this.cityCode.length > 3 ? this.cityCode[2] : "";
    },
    industryChange() {
      this.param.industryClassificationId =
        this.industry.length > 0 ? this.industry[this.industry.length - 1] : "";
    },
    hotKeywordClick(item) {
      this.keyword = this.param.query = item.name;
    },
    pageChange(v) {
      this.param.pageNum = v;
    },
    search() {
      this.param.query = this.keyword;
    },
    focusSearch() {
      let that = this;
      this.searchFocus = true;
      let blur = function () {
        that.searchFocus = false;
        document.removeEventListener("click", blur);
      };
      document.addEventListener("click", blur);
    },
    // searchCompanyListReal(){
    //   this.inSearchInterval = true;
    //   this.param.industryClassificationId = this.industry.length>0?this.industry[this.industry.length - 1]:'';
    //   this.param.cityCode = this.cityCode.length>2?this.cityCode[1]:"";
    //   this.param.areaCode = this.cityCode.length>3?this.cityCode[2]:"";
    //   searchCompanyList(this.param).then(res => {
    //     if(res.code === 0){
    //       this.jobList = res.data.rows;
    //       this.total = res.data.total;
    //     }
    //   });
    // },
    searchCompanyList() {
      searchCompanyList(this.param).then((res) => {
        if (res.code === 0) {
          this.jobList = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="less">
@import "../assets/css/variable";
.page_company_list {
  .company_list {
    margin-top: 30px;
  }
  .filter_area {
    .el-form-item__label {
      color: #333;
      font-size: 15px;
    }
    .el-form {
      .el-select .el-input.is-focus .el-input__wrapper,
      .el-select .el-input__wrapper.is-focus,
      .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
        box-shadow: none !important;
      }
      .el-select {
        width: 100%;
      }
      .el-input__wrapper {
        background-color: #faf9f9;
        box-shadow: none;
      }
      .el-input__wrapper.is-focus {
        box-shadow: none;
      }
      .el-textarea__inner {
        background-color: #faf9f9;
        border: none;
        box-shadow: none;
      }
      .el-textarea__inner:focus {
        box-shadow: none;
      }
      .el-input__inner {
        height: 40px;
        line-height: 40px;
        border: none;
      }
      .el-form-item {
        align-items: center;
      }
    }
    .el-form--inline {
      align-items: center;
      text-align: center;
      padding: 40px;
      background-color: #fff;
      .el-input__inner {
        height: 35px;
        line-height: 35px;
      }
      .el-input__wrapper {
        height: 35px;
        line-height: 35px;
      }
      .el-form-item {
        align-items: center;
        margin-bottom: 0;
      }
    }
  }
  .hot_search {
    font-size: 14px;
    color: #999999;
    width: 1020px;
    margin: 0;
    text-indent: 37px;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .search_bar {
    box-sizing: border-box;
    border: 1px solid #fff;
    position: relative;
    background-color: #fff;
    width: 1020px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    margin: 50px auto 10px;
    .search_btn {
      width: 80px;
      height: 40px;
      border-radius: 20px;
      background-color: @blue_color;
      margin-right: 8px;
      color: #fff;
    }
    .btn_show_type {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      cursor: pointer;
      color: #1a1a1a;
    }
    .el-select {
      width: 120px;

      .el-input__inner {
        border: none;
        background-color: transparent;
        text-align: center;
        font-size: 16px;
        color: #1a1a1a;
      }
      //.el-input .el-select__caret {
      //  display:block;
      //  width: 9px;
      //  height: 5px;
      //  background:url(../assets/img/icon_triangle.png) no-repeat center center / 100% auto;
      //  svg{
      //    display:none;
      //  }
      //}
      //.el-input .is-reverse{
      //  transform: rotate(180deg);
      //}
    }
  }
  .search_focus {
    border: 1px solid @blue_color;
  }
  .company_list {
    display: flex;
    flex-wrap: wrap;
    .company_card {
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .el-pagination {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
}
</style>
